import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Header from "../components/header"

export default function Term() {
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>聲明及條款 | 開心按揭服務有限公司</title>
      </Helmet>
      <Navbar />
      <div className="content">
        <Header text="terms_header" />
        <div id="term">
          <div id="disclaimer" className="term_content">
            <div className="terms_title">
              <h3>免責聲明</h3>
            </div>
            <p>
              本網頁記載的資料僅供一般參考之用，不宜視為專業意見。請翻閱此等資料的人士在需要時，尋求適當的專業指導。開心按揭服務有限公司（＂本公司＂）對此等資料的提供，已力求準確、完整和及時。倘資料有誤或欠全，本公司恕不承擔任何責任。
              <br />
              <br />
              本網頁介紹的產品和服務僅適用於法律上接受本公司提供此等產品和服務的地區之任何人士，而對於法律上就本公司提供此等產品或服務有所限制的地區之人士，本網頁的資料不應視作任何推介或招攬。請翻閱者自行負責了解和遵守當地的有關法律和法規。
              <br />
              <br />
              本網頁資料不具任何約束性、責任性，本公司可隨時更改或撤回有關產品及服務而毋須事先通知。
              <br />
              <br />
              在此等資訊及資料的有關方面，本公司並不就某一特定用途的非侵權、保安、準確性、適合性、或不含電腦病毒作出任何保證。
            </p>
          </div>
          <div className="separator" />
          <div id="privacy" className="term_content">
            <div className="terms_title">
              <h3>私隱條例</h3>
            </div>
            <p>
              關於個人(資料/私隱)條例 (下稱"條例")
              <ol>
                <li>
                  客戶在開立或延續戶口、建立或延續信貸服務時，需要不時向本公司提供有關的資料。
                </li>
                <li>
                  若未能向本公司提供該等資料可能會導致無法開立或延續戶口或延續信貸服務。
                </li>
                <li>
                  客戶與本公司在延續正常業務運作中，本公司亦會收集客戶的資料。
                </li>
                <li>
                  客戶的資料可能會用於下列用途：
                  <ol type="i">
                    <li>提供服務和信貸便利給客戶之日常運作；</li>
                    <li>
                      在客戶申請信貸時進行的信貸調查，及每年進行一次或以上的定期或特別審查；
                    </li>
                    <li>編制及維持本公司的信貸評分模式；</li>
                    <li>協助其他財務機構作信用檢查及追討債務；</li>
                    <li>確保客戶維持可靠信用；</li>
                    <li>設計為客戶使用的財務服務或有關產品；</li>
                    <li>推廣財務服務或有關產品；</li>
                    <li>計算本公司與客戶之間的債務；</li>
                    <li>向客戶及為客戶的責任提供抵押的人仕追收欠款；</li>
                    <li>
                      本公司為履行任何對其有約束力的法例的規定而作出披露；
                    </li>
                    <li>
                      使本公司的實在或建議承讓人；或本公司對客戶的權利的參與人底附屬參與人評核圖成為轉讓，參與或附屬參與的交易；
                    </li>
                    <li>與上述有關的用途</li>
                  </ol>
                </li>
                <li>
                  本公司會對其持有的客戶資料保密，但本公司可能會把該等資料提供給下述各方作第（４）段列出的用途：
                  <ol type="i">
                    <li>
                      任何代理人、承包人、或向本公司提供行政、電訊、電腦、付款或證券結算或其他與銀行業務運作有關的服務的第三方服務供應者；
                    </li>
                    <li>
                      任何對本公司有保密責任的人，包括本公司集團內已承諾保持該資料保密的公司；
                    </li>
                    <li>
                      付款銀行向出票人提供已付款支票的副本（而其中可能載有關於收款人的資料）；
                    </li>
                    <li>
                      信貸資料機構；而在客戶欠賬時，則可將該等資料提供收數公司；
                    </li>
                  </ol>
                </li>
                <li>
                  根據條例中的條款及根據條例核准和發出的個人信貸資料實務守則，任何客戶有權：
                  <ol type="i">
                    <li>查核本公司是否持有他的資料及查閱該等資料；</li>
                    <li>要求本公司改正任何有關他的不準確的資料；</li>
                    <li>
                      查明本公司對於資料的政策及慣例和獲告知本公司持有的個人資料種類；
                    </li>
                    <li>
                      查詢並獲本公司回覆，例行向信貸資料機構或收數公司披露的個人資料類別，及獲本公司提供進一步資料，以便向有關信貸資料機構或收數公司提出查閱和改正資料的要求；
                    </li>
                    <li>
                      於悉數清償欠款而結束賬戶時，指示本公司要求該信貸資料機構，從資料庳刪除本公司曾經提供的賬戶資料，惟是項指示須於結束賬戶後五年內發出，而該賬戶在緊接結束之前五年內，並無拖欠超過６０天的記錄。假如該賬戶有拖欠超過６０天的記錄，信貸資料機構可以保留有關記錄，直至欠款悉數清償之日起計滿５年為止，或銀行接獲的解除破產令生效日期起計滿５年為止，以較早發生者為準。
                    </li>
                  </ol>
                </li>
                <li>
                  本公司在批核信貸申請時，可能參考由信貸資料機構提供有關客戶的信貸報告。假如客戶有意索取有關報告，可要求本公司提供有關信貸資料機構的聯絡詳情。
                </li>
                <li>
                  根據條例的條款，本公司有權就處理任何查閱資料的要求收取合理費用。
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
